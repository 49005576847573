import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { CookiesProvider } from 'react-cookie'

import store from './state/store'
import Updater from './state/updater'
import App from './App'
import { FALLBACK_PROVIDER_NAME } from './lib/constants'
import getLibrary from './lib/web3/get-library'

const Web3ProviderFallback = createWeb3ReactRoot(FALLBACK_PROVIDER_NAME)

const element = document.getElementById('loading_screen')
element.remove()

ReactDOM.render(
  <Provider store={store()}>
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderFallback getLibrary={getLibrary}>
          <Updater />
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </Web3ProviderFallback>
      </Web3ReactProvider>
    </StrictMode>
  </Provider>,
  document.getElementById('top_buttons_container')
)
