import { useMemo } from 'react'
import { useActiveWeb3React, useWeb3ForChainId } from './web3'
import axios from 'axios'

import { isTransactionSuccess, replaceIpfsIfNeeded } from '../js/libs/appUtils'
import Web3 from 'web3'
import { useGridNFTContract, useMintManagerContract } from './use-contract'
import GridNFTAbiL1 from '../contracts/abi.json'
import processPaintedEvent from '../lib/actions/process-painted-event'
import ERC1155 from '../contracts/abi.json'
import BigNumber from 'bignumber.js/bignumber'

export const useGridNFTDataSource = () => {
  const contract = useGridNFTContract()
  const mintManagerContract = useMintManagerContract()
  const { account, chainId, library } = useActiveWeb3React()
  const readOnlyWeb3WebSocket = useWeb3ForChainId(chainId)
  return useMemo(() => {
    const getPaintedEventsForTokenId = (
      tokenId,
      fromBlock = 'earliest',
      toBlock = 'latest'
    ) => {
      return contract.getPastEvents('Painted', {
        filter: { tokenId: Web3.utils.toBN(tokenId) },
        fromBlock: fromBlock,
        toBlock: toBlock
      })
    }

    const getPaintedEventsForTokenIds = (
      tokenIds,
      fromBlock = 'earliest',
      toBlock = 'latest'
    ) => {
      const bnTokenIds = tokenIds.map(id => Web3.utils.toBN(id))
      return contract.getPastEvents('Painted', {
        filter: { tokenId: bnTokenIds },
        fromBlock: fromBlock,
        toBlock: toBlock
      })
    }

    const getCurrentGridContentsBlock = tokenId => {
      return contract.methods.getCurrentGridContentsBlock(tokenId).call()
    }
    const getCurrentMintMode = () => {
      return mintManagerContract.methods.mintMode().call()
    }

    return {
      checkCanMintPublic: async () => {
        const currentMintMode = await getCurrentMintMode()
        return parseInt(currentMintMode) === 2
      },

      mintGridNft: async amountToMint => {
        const publicSalePrice = await mintManagerContract.methods
          .mintPricePublic()
          .call()
        const mintCost = new BigNumber(publicSalePrice).times(amountToMint)
        return contract.methods
          .mint(amountToMint)
          .send({ from: account, value: mintCost })
      },
      setPixelsOnGrid: (
        tokenID,
        colorIndex,
        pixelGroups,
        senderAddress = account
      ) => {
        return new Promise(function (resolve, reject) {
          contract.methods
            .setPixels(Web3.utils.toBN(tokenID), colorIndex, pixelGroups)
            .send({ from: senderAddress })
            .on('receipt', function (receipt) {
              if (isTransactionSuccess(receipt)) {
                resolve(receipt.events.Painted)
              } else {
                reject('Transaction Failed')
              }
            })
            .on('confirmation', function (confirmationNumber, receipt) {
              if (isTransactionSuccess(receipt)) {
                resolve(receipt.events.Painted)
              } else {
                reject('Transaction Failed')
              }
            })
            .on('transactionHash', function (hash) {
              console.log('transactionHash', hash)
            })
            .on('error', function (error, receipt) {
              console.error(error)
              reject(error)
            })
        })
      },

      getCurrentGridContents: async tokenId => {
        let blockNumber
        try {
          blockNumber = await getCurrentGridContentsBlock(
            Web3.utils.toBN(tokenId)
          )
        } catch (e) {
          console.log(e)
          blockNumber = 'earliest'
        }

        const events = await getPaintedEventsForTokenId(
          tokenId,
          blockNumber,
          blockNumber !== 'earliest' ? blockNumber : 'latest'
        )
        if (events.length === 0) {
          throw new Error('No events found for tokenId ' + tokenId)
        }

        return events.length && processPaintedEvent(events[0])
      },

      getCurrentGridContentsBlock,
      getPaintedEventsForTokenId,
      getPaintedEventsForTokenIds,
      getTotalGridContentsIterations: tokenId => {
        return contract.methods.getTotalGridContentsIterations(tokenId).call()
      },
      getPaintTokenCost: () => {
        return contract.methods.PRICE_PER_PIXEL().call()
      },
      getGridWidth: () => {
        return contract.methods.GRID_WIDTH().call()
      },
      getCoverageCost: () => {
        return contract.methods.COVERAGE_COST().call()
      },
      calculatePaintTokenCostForPixels: async pixels => {
        const costPerPixel = await contract.methods.PRICE_PER_PIXEL().call()
        return Web3.utils.toBN(pixels).mul(Web3.utils.toBN(costPerPixel))
      },
      subscribeToPaintedEvents: callback => {
        const abiToUse = GridNFTAbiL1

        // get guaranteed web socket connection for chain
        const web3 = readOnlyWeb3WebSocket

        const deployedNetwork = abiToUse.networks[chainId]
        const instance = new web3.eth.Contract(
          abiToUse.abi,
          deployedNetwork && deployedNetwork.address
        )
        instance.events
          .Painted({ fromBlock: 'latest' })
          .on('data', async function (event) {
            callback(event)
          })
          .on('error', async function (error) {
            console.error(error)
          })
      },
      getBalanceForAddress: address => {
        return contract.methods.balanceOf(address).call()
      },
      ownerOf: tokenId => {
        return contract.methods.ownerOf(tokenId).call()
      },
      name: () => {
        return contract.methods.name().call()
      },
      symbol: () => {
        return contract.methods.symbol().call()
      },
      totalSupply: () => {
        return contract.methods.totalSupply().call()
      },
      getOwnedTokenIdsInIndexRange: (ownerAddress, startIndex, endIndex) => {
        const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
          startIndex,
          endIndex + 1
        )

        return Promise.all(
          indexes.map(index =>
            contract.methods.tokenOfOwnerByIndex(ownerAddress, index).call()
          )
        )
      },
      getOwnedTokenIdAtIndex: (ownerAddress, index) => {
        return contract.methods.tokenOfOwnerByIndex(ownerAddress, index).call()
      },
      transferTokenId: (senderAddress, recipientAddress, tokenId) => {
        return contract.methods
          .transferFrom(
            senderAddress,
            recipientAddress,
            Web3.utils.toBN(tokenId)
          )
          .send({
            from: senderAddress
          })
      },
      setApproveTokenTransfer: (ownerAddress, spenderAddress, approved) => {
        return contract.methods
          .setApprovalForAll(spenderAddress, approved)
          .send({
            from: ownerAddress
          })
      },
      isTokenTransferApproved: (ownerAddress, spenderAddress) => {
        return contract.methods
          .isApprovedForAll(ownerAddress, spenderAddress)
          .call()
      },
      getTokenUri: tokenId => {
        return contract.methods.tokenURI(tokenId).call()
      },
      isTokenInFreeForAllMode: tokenId => {
        return contract.methods.freeForAllMode(tokenId).call()
      },
      setFreeForAllModeForToken: (tokenId, freeForAll, ownerAddress) => {
        return contract.methods
          .setFreeForAllForToken(tokenId, freeForAll)
          .send({
            from: ownerAddress
          })
      },
      ownsFreeForAllAllowToken: address => {
        return new Promise(async function (resolve, reject) {
          const erc1155TokenContractAddress = await contract.methods
            .allowTokenAddress()
            .call()
          const erc1155TokenId = await contract.methods.allowTokenId().call()
          const erc1155Contract = new library.eth.Contract(
            ERC1155.abi,
            erc1155TokenContractAddress
          )

          const balance = erc1155Contract.methods
            .balanceOf(address, erc1155TokenId)
            .call()
          resolve(new BigNumber(balance).gt(new BigNumber(0)))
        })
      },
      getOffchainRemoteData: tokenId => {
        return new Promise(async function (resolve, reject) {
          try {
            const url = await contract.methods.tokenURI(tokenId).call()
            const res = await fetch(url)
            const tokenInfo = await res.json()

            resolve(tokenInfo)
          } catch (error) {
            reject(error)
          }
        })
      },
      getFullTokenUriMetadata: async tokenId => {
        let tokenUri = await contract.methods.tokenURI(tokenId).call()

        tokenUri = replaceIpfsIfNeeded(tokenUri)

        if (tokenUri.indexOf('http') === -1) {
          tokenUri = 'https://' + tokenUri
        }

        let returnData
        if (
          tokenUri &&
          tokenUri.startsWith('https://data:application/json;base64')
        ) {
          const json = Buffer.from(
            tokenUri.replace('https://data:application/json;base64', ''),
            'base64'
          )
          returnData = JSON.parse(json)
        } else {
          const result = await axios.get(tokenUri, {})
          returnData = result.data
        }

        return returnData
      }
    }
  }, [contract, chainId, readOnlyWeb3WebSocket])
}
