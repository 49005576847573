import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react'
import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

import { SupportedChain, SUPPORTED_CHAINS } from '../constants'
import { NetworkProviderWebSocketUrls, NetworkProviderHttpUrls } from './urls'

export const gnosisSafe = new SafeAppConnector()

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINS
})

export const fallback = new NetworkConnector({
  urls: NetworkProviderHttpUrls,
  // Fallback to mainnet in production
  defaultChainId: SupportedChain.Ethereum
})

export const fallbackL2 = new NetworkConnector({
  urls: NetworkProviderHttpUrls,
  defaultChainId: SupportedChain.Polygon
})

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAINS,
  rpc: NetworkProviderHttpUrls,
  qrcode: true
})
