import React from 'react'
import { useSelector } from 'react-redux'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import layer1Theme from './layer1'
import layer2Theme from './layer2'

const getTheme = isLayer2 => isLayer2 ? layer2Theme : layer1Theme

const MurAllThemeProvider = (props) => {
  const network = useSelector(state => state.network)
  const theme = getTheme(network.isLayer2)
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          {props.children}
        </StyledThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default MurAllThemeProvider
