import React, { useState } from 'react'
import { UnsupportedChainIdError } from '@web3-react/core'
import ConnectWalletButton from '../components/common/buttons/ConnectWalletButton'
import ConnectWalletModal from '../components/common/wallet/connect-modal'
import MintingForm from '../components/common/mint/MintForm'
import Error from '../components/common/buttons/ErrorConnectWalletButton'
import switchToNetwork from '../lib/actions/switch-network'
import { useActiveWeb3React } from '../hooks/web3'
import { SupportedChain } from '../lib/constants'
import LazyImage from '../components/common/images/LazyImage'
import makeStyles from '@mui/styles/makeStyles'
import Footer from '../components/common/footer'
import { Box, Grid, useMediaQuery } from '@mui/material'
import MintUpsell from '../components/common/mint/MintUpsell'
import DefaultContainer from '../components/common/DefaultContainer'

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeblur': {
    '0%': {
      filter: 'blur(0px) brightness(100%)',
      webkitFilter: 'blur(0px) brightness(100%)',
    },
    '100%': {
      filter: 'blur(8px) brightness(50%)',
      webkitFilter: 'blur(8px) brightness(50%)',
    },
  },
  '@keyframes fade': {
    '0%': {
      filter: 'brightness(100%)',
      webkitFilter: 'brightness(100%)',
    },
    '100%': {
      filter: 'brightness(50%)',
      webkitFilter: 'brightness(50%)',
    },
  },

  fadeblur: {
    animationName: '$fade',
    animationDuration: '500ms',
    animationFillMode: 'forwards',
    webkitAnimationFillMode: 'forwards',
  },
}))

const MintPage = () => {
  const [openModal, setOpenModal] = useState(false)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <LazyImage
        className={classes.fadeblur}
        src={'images/BoringSecurityMurAll.png'}
        lqip={'images/BoringSecurityMurAll_small.png'}
        alt="Boring Security MurAll"
        containerStyle={{
          position: 'fixed',
          zIndex: -1,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
        }}
        style={{
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
        }}
      />

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <DefaultContainer>
          <Grid
            container
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            ml={isMobile ? 0 : 2}
            mr={isMobile ? 0 : 2}
            sx={{
              flex: 1,
              height: '100%',
            }}
          >
            <Grid item xs={12} sm={6} spacing={3}>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'stretch',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Content open={() => setOpenModal(true)} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} spacing={3}>
              <MintUpsell />
            </Grid>
          </Grid>
        </DefaultContainer>
      </Box>

      <ConnectWalletModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />
      <Box
        sx={{
          alignSelf: 'flex-end',
          width: '100%',
        }}
      >
        <Footer />
      </Box>
    </Box>
  )
}

const Content = ({ open }) => {
  const { account, error, library } = useActiveWeb3React()
  const [amount, setAmount] = useState(1)

  if (error)
    return (
      <Error
        onClick={() => {
          open()
          switchToNetwork({ library, chainId: SupportedChain.Ethereum })
        }}
        text={
          error instanceof UnsupportedChainIdError
            ? 'Unsupported Network'
            : 'Error'
        }
      />
    )

  if (account) {
    return (
      <MintingForm
        openAddressModal={open}
        amount={amount}
        setAmount={setAmount}
      />
    )
  }

  return <ConnectWalletButton onClick={open} text="Connect Your Wallet" />
}

export default MintPage
