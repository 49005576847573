import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useActiveWeb3React } from '../hooks/web3'
import { setNetwork } from './slices/network'
import { addPaintedEvents } from './slices/events'
import processPaintedEvent from '../lib/actions/process-painted-event'
import { paintedEvent } from '../lib/notification'
import { useGridNFTDataSource } from '../hooks/use-grid-nft-datasource'

export default () => {
  const dispatch = useDispatch()
  const { chainId } = useActiveWeb3React()
  const { subscribeToPaintedEvents } = useGridNFTDataSource()

  // change network in state
  useEffect(() => {
    if (!chainId) return
    dispatch(setNetwork({ id: parseInt(chainId) }))
  }, [chainId])

  // listen to Painted events
  useEffect(() => {
    if (!chainId) return
    try {
      subscribeToPaintedEvents(async rawEvent => {
        console.log('Painted event', rawEvent)
        const event = await processPaintedEvent(rawEvent)
        if (!event) return
        paintedEvent(event)
        dispatch(addPaintedEvents([event]))
      })
    } catch (error) {
      console.error(error)
    }
  }, [chainId, subscribeToPaintedEvents])

  return null
}
