import { useState, useEffect, useCallback } from 'react'
import { useGridNFTContract } from '../use-contract'

export const useGetMaxGridSupply = (immediate = true) => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)

  const contract = useGridNFTContract()

  const execute = useCallback(async () => {
    if (!contract) return

    setIsLoading(true)
    setValue(null)
    setError(null)
    let result
    try {
      result = await contract.methods.MAX_SUPPLY().call()

      setValue(result)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
    return result
  }, [contract])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, value, error }
}

export default useGetMaxGridSupply
