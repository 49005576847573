import { SupportedChain } from '../constants'

export const NetworkProviderWebSocketUrls = {
  [SupportedChain.Ethereum]:
    'wss://eth-mainnet.alchemyapi.io/v2/JBcsj6b99HP27jZ-QRJ5ekt1PDVXicHS',
  // [SupportedChain.Ropsten]:
  //   'wss://eth-ropsten.alchemyapi.io/v2/gnUvQSfMX8aP4LheyAn7ny0CVOwCEP6u',
  [SupportedChain.Rinkeby]:
    'wss://eth-rinkeby.alchemyapi.io/v2/puDsFO1WUH-LWm51l3K94J4PQTEZve9H',
  // [SupportedChain.Goerli]:
  //   'wss://eth-goerli.alchemyapi.io/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn',
  // [SupportedChain.Polygon]:
  //   'wss://polygon-mainnet.g.alchemy.com/v2/PLh-zCcMAGuB2pHN3c1Lz1x_5EIW_cps',
  [SupportedChain.Mumbai]: 'wss://rpc-mumbai.matic.today',
  [SupportedChain.LocalhostL1]: 'http://0.0.0.0:8545',
  [SupportedChain.LocalhostL2]: 'http://0.0.0.0:8546'
}

export const NetworkProviderHttpUrls = {
  [SupportedChain.Ethereum]:
    'https://eth-mainnet.alchemyapi.io/v2/JBcsj6b99HP27jZ-QRJ5ekt1PDVXicHS',
  // [SupportedChain.Ropsten]:
  //   'https://eth-ropsten.alchemyapi.io/v2/gnUvQSfMX8aP4LheyAn7ny0CVOwCEP6u',
  [SupportedChain.Rinkeby]:
    'https://eth-rinkeby.alchemyapi.io/v2/puDsFO1WUH-LWm51l3K94J4PQTEZve9H',
  // [SupportedChain.Goerli]:
  //   'https://eth-goerli.alchemyapi.io/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn',
  // [SupportedChain.Polygon]:
  //   'https://polygon-mainnet.g.alchemy.com/v2/PLh-zCcMAGuB2pHN3c1Lz1x_5EIW_cps',
  [SupportedChain.Mumbai]: 'https://rpc-mumbai.matic.today',
  [SupportedChain.LocalhostL1]: 'http://0.0.0.0:8545',
  [SupportedChain.LocalhostL2]: 'http://0.0.0.0:8546'
}

export default NetworkProviderWebSocketUrls
