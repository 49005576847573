import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import ThemeProvider from './theme/Provider'
import Routes from './routes'
import Notifications from 'components/common/notifications'

const App = () => {
  return (
    <ThemeProvider>
      <Notifications />
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  )
}

export default App
