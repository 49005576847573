import { useMemo } from 'react'
import { useActiveWeb3React } from './web3'
import { SupportedChain } from '../lib/constants'

import GridNFTAbiL1 from '../contracts/abi.json'
import MintManagerAbiL1 from '../contracts/abi.json'
import PaintContractAbiL1 from '../contracts/abi.json'
import PaintContractAbiL2 from '../contracts/abi.json'
import MurAllFramesContract from '../contracts/abi.json'

// returns null on errors
export const useContract = (buildMap, overrideAddress = null) => {
  const { library, chainId } = useActiveWeb3React()
  return useMemo(() => {
    const buildFile = buildMap && buildMap[chainId]
    if (!buildFile || !library || !chainId) return null
    const ABI = buildFile.abi
    const networkData = buildFile.networks[chainId]
    const address = overrideAddress || (networkData && networkData.address)
    if (!ABI || !address) return null
    try {
      return new library.eth.Contract(ABI, address)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [buildMap, library, chainId, overrideAddress])
}

const generateBuildMap = (layer1Abi, layer2Abi) => {
  return useMemo(() => {
    return {
      [SupportedChain.Ethereum]: layer1Abi,
      [SupportedChain.Ropsten]: layer1Abi,
      [SupportedChain.Rinkeby]: layer1Abi,
      [SupportedChain.Goerli]: layer1Abi,
      [SupportedChain.LocalhostL1]: layer1Abi,
      [SupportedChain.Polygon]: layer2Abi,
      [SupportedChain.Mumbai]: layer2Abi,
      [SupportedChain.LocalhostL2]: layer2Abi
    }
  }, [layer1Abi, layer2Abi])
}

export const useGridNFTContract = () => {
  const buildMap = generateBuildMap(GridNFTAbiL1, null)
  return useContract(buildMap)
}

export const usePaintContract = () => {
  const buildMap = generateBuildMap(PaintContractAbiL1, PaintContractAbiL2)
  return useContract(buildMap)
}

export const useMintManagerContract = () => {
  const buildMap = generateBuildMap(MintManagerAbiL1, null)
  return useContract(buildMap)
}

export const useMurAllFramesContract = (address = null) => {
  const buildMap = generateBuildMap(MurAllFramesContract, null)
  return useContract(buildMap, address)
}
