export const FALLBACK_PROVIDER_NAME = 'FALLBACK'
export const FALLBACKL2_PROVIDER_NAME = 'FALLBACKL2'
export const MAX_UINT = '9999999999999999'
export const IS_IN_IFRAME = window.parent !== window

export const SupportedChain = {
  Ethereum: 1,
  Ropsten: 3,
  Rinkeby: 4,
  Goerli: 5,
  Polygon: 137,
  Mumbai: 80001,
  LocalhostL1: 123456789,
  LocalhostL2: 987654321
}

export const SUPPORTED_CHAINS = [
  SupportedChain.Ethereum,
  SupportedChain.Ropsten,
  SupportedChain.Rinkeby,
  SupportedChain.Goerli,
  SupportedChain.Polygon,
  SupportedChain.Mumbai,
  SupportedChain.LocalhostL1,
  SupportedChain.LocalhostL2
]

export const L2_CHAINS = [
  SupportedChain.Polygon,
  SupportedChain.Mumbai,
  SupportedChain.LocalhostL2
]

export const CHAIN_INFO = {
  [SupportedChain.Ethereum]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 }
  },
  [SupportedChain.Rinkeby]: {
    explorer: 'https://rinkeby.etherscan.io/',
    label: 'Rinkeby',
    nativeCurrency: { name: 'Rinkeby ETH', symbol: 'rinkETH', decimals: 18 }
  },
  [SupportedChain.Ropsten]: {
    explorer: 'https://ropsten.etherscan.io/',
    label: 'Ropsten',
    nativeCurrency: { name: 'Ropsten ETH', symbol: 'ropETH', decimals: 18 }
  },
  [SupportedChain.Goerli]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Görli',
    nativeCurrency: { name: 'Görli ETH', symbol: 'görETH', decimals: 18 }
  },
  [SupportedChain.Polygon]: {
    explorer: 'https://polygonscan.com/',
    label: 'Polygon',
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
    rpcUrls: [
      'https://polygon-rpc.com',
      'wss://rpc-mainnet.matic.network',
      'https://rpc-mainnet.maticvigil.com'
    ]
  },
  [SupportedChain.Mumbai]: {
    explorer: 'https://mumbai.polygonscan.com/',
    label: 'Mumbai',
    rpcUrls: [
      'https://rpc-mumbai.maticvigil.com/',
      'https://rpc-mumbai.matic.today',
      'wss://rpc-mumbai.matic.today'
    ],
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 }
  }
}

export const CANVAS_WIDTH = 160
export const CANVAS_HEIGHT = 160
export const GRID_NUM_COLUMNS = 10
export const GRID_NUM_ROWS = 7
