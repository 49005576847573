import config from '../../../config.js'
import Base_layers_class from '../../../core/base-layers.js'
import {
  isLittleEndian,
  createOffscreenCanvas,
  disableCanvasSmooth,
  convertCanvasTo256Color,
  getImageCropDataFromCanvasContext,
  cropImage
} from '../../../libs/appUtils'

import Worker from '../../../workers/blockchain/parsePixelDataToGridNftTransactionData.worker'
import Web3 from 'web3'
import { drawNftDataToCanvasBuffer } from '../GridtNftDataMapper.js'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../../../../lib/constants.js'

const PAINT_PER_PIXEL = '1000000000000000000'
class Get_transaction_data_for_layers_usecase {
  constructor () {
    this.Base_layers = new Base_layers_class()
  }

  async execute ({
    layerId = config.layer.id,
    flatten = false,
    convertTo256Color = false
  } = {}) {
    var _this = this
    return new Promise(async function (resolve, reject) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = config.WIDTH
      canvas.height = config.HEIGHT
      disableCanvasSmooth(ctx)

      if (flatten) {
        _this.Base_layers.convert_layers_to_canvas(ctx)
      } else {
        _this.Base_layers.convert_layers_to_canvas(ctx, layerId)
      }

      if (convertTo256Color) {
        await convertCanvasTo256Color(canvas)
      }

      // Get the Canvas image data from the given coordinates and dimensions
      const imageData = ctx.getImageData(0, 0, config.WIDTH, config.HEIGHT)

      // check endian-ness to ensure we deal with the colour hex correctly
      const isDeviceLittleEndian = isLittleEndian()

      // map pixel data to blockchain transaction data

      const buffers = [imageData.data.buffer]
      const message = {
        canvasBuffer: imageData.data.buffer,
        isLittleEndian: isDeviceLittleEndian,
        buffers: buffers,
        alphaEnabled: config.layer.alphaEnabled
      }

      const worker = new Worker()

      worker.onmessage = async function (msg) {
        worker.terminate()

        const numberOfColors = msg.data.numberOfColors
        if (numberOfColors > 256) {
          reject('Too many colours')
        }
        const blockchainPixelData = msg.data.blockchainPixelData
        const blockchainPixelGroupData = msg.data.blockchainPixelGroupData
        const colourIndexData = msg.data.colourIndexData
        const numberOfPixels = msg.data.numberOfPixels

        const offScreenCanvas = createOffscreenCanvas(
          config.WIDTH,
          config.HEIGHT
        )
        const ctx = offScreenCanvas.getContext('2d')
        const img = ctx.getImageData(0, 0, config.WIDTH, config.HEIGHT)
        const canvasBuffer = await drawNftDataToCanvasBuffer(
          img.data.buffer,
          colourIndexData,
          blockchainPixelGroupData
        )

        const arr = new Uint8ClampedArray(canvasBuffer)
        let processedImageData
        try {
          processedImageData = new ImageData(arr, config.WIDTH, config.HEIGHT)
        } catch (e) {
          processedImageData = ctx.createImageData(config.WIDTH, config.HEIGHT)
          processedImageData.data.set(arr)
        }

        ctx.putImageData(processedImageData, 0, 0)
        const fullBase64PngString = offScreenCanvas.toDataURL()

        resolve({
          colourIndexData: colourIndexData,
          blockchainPixelData: blockchainPixelData,
          blockchainPixelGroupData: blockchainPixelGroupData,
          numberOfPixels: numberOfPixels,
          costInPaint: Web3.utils
            .toBN(numberOfPixels)
            .mul(Web3.utils.toBN(PAINT_PER_PIXEL)),
          fullBase64PngString: fullBase64PngString,
          croppedBase64PngString: fullBase64PngString,
          numberOfColors: numberOfColors
        })
      }
      worker.postMessage(message, buffers)
    })
  }
}

export default Get_transaction_data_for_layers_usecase
