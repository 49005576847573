import React from 'react'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'

const MemberBenefits = () => {
  return (
    <List
      sx={{
        width: '100%',
        listStyleType: 'disc',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary='1 Vote in the "Boring Security DAO Funds Pool (coming soon)"'
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`The ability to introduce or 'co-sponsor' proposals to access the Boring Security DAO Funds Pool (coming soon).`}
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`Access to draw on your portion of the Supporter Grid (displayed on our front-page)`}
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`Access to a special channel in the Discord with increased access to our founders and contributors`}
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`Discounted products and services`}
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`Our eternal gratitude ❤️`}
      />
    </List>
  )
}

export default MemberBenefits
