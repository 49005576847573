import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import ErrorMessageView from '../components/common/error-view'
import LoadingSpinner from '../components/common/loader'
import { useGridNFTDataSource } from '../hooks/use-grid-nft-datasource'
import SvgGridViewer from '../components/common/svg/svg-grid-viewer'
import FramesWrapperView from '../components/common/frames/frame-wrapper-view'
import GridNftPreviewDialog from '../components/common/nft/grid-nft-preview-dialog'
import { Box } from '@mui/material'
import PoweredByMurAllView from '../components/common/powered-by-murall-view'
import LazyImage from '../components/common/images/LazyImage'
import makeStyles from '@mui/styles/makeStyles'
import Footer from '../components/common/footer'
const DEFAULT_FADE_DURATION_MILLIS = 350

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeblur': {
    '0%': {
      filter: 'blur(0px) brightness(100%)',
      webkitFilter: 'blur(0px) brightness(100%)',
    },
    '100%': {
      filter: 'blur(8px) brightness(50%)',
      webkitFilter: 'blur(8px) brightness(50%)',
    },
  },
  '@keyframes fade': {
    '0%': {
      filter: 'brightness(100%)',
      webkitFilter: 'brightness(100%)',
    },
    '100%': {
      filter: 'brightness(50%)',
      webkitFilter: 'brightness(50%)',
    },
  },

  fadeblur: {
    animationName: '$fade',
    animationDuration: '500ms',
    animationFillMode: 'forwards',
    webkitAnimationFillMode: 'forwards',
  },
}))

const LivePage = ({ showMurAllLogoSpinner, style }) => {
  const classes = useStyles()
  const [cookies] = useCookies(['agreedToTerms'])

  const [currentSelectedState, setCurrentSelectedState] = useState(null)
  const [currentSelectedStateImage, setCurrentSelectedStateImage] =
    useState(null)
  const [gridNftDialogOpen, setGridNftDialogOpen] = useState(false)
  const [totalSupply, setTotalSupply] = useState([])
  const [loading, setLoading] = useState(true)
  const { totalSupply: getTotalSupply } = useGridNFTDataSource()

  const network = useSelector((state) => state.network)
  const terms = useSelector((state) => state.terms)
  const frames = useSelector((state) => {
    return state.frames
  })

  const agreedToTerms = !!cookies.agreedToTerms || terms.agreed

  useEffect(() => {
    if (!network.id || !network.supported) {
      return
    }
    setLoading(true)
    getTotalSupply().then((totalSupply) => {
      setTotalSupply(totalSupply)
      setLoading(false)
    })
  }, [network, getTotalSupply])

  if (loading) return <LoadingSpinner useLogo={showMurAllLogoSpinner} />

  if (network.id && !network.supported)
    return (
      <ErrorMessageView
        title="Network unsupported"
        description="Please select the Ethereum Mainnet or Polygon network"
      />
    )

  return (
    <>
      <Box
        sx={{
          ...style,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <LazyImage
          className={classes.fadeblur}
          src={'images/BoringSecurityMurAll.png'}
          lqip={'images/BoringSecurityMurAll_small.png'}
          alt="Boring Security MurAll"
          containerStyle={{
            position: 'fixed',
            zIndex: -1,
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
          }}
          style={{
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
          }}
        />
        {agreedToTerms && (
          <FramesWrapperView
            {...(frames &&
              frames &&
              frames.assignedFrameId !== null && {
                tokenId: frames.assignedFrameId,
              })}
            style={{
              width: '100%',
              flex: 1,
            }}
          >
            <SvgGridViewer
              totalSupply={parseInt(totalSupply)}
              selectedStateIndex={currentSelectedState}
              animationDuration={DEFAULT_FADE_DURATION_MILLIS}
              onGridStateClick={(tokenId, image) => {
                console.log('tokenId', tokenId)
                setCurrentSelectedStateImage(image)
                setCurrentSelectedState(tokenId)
                setGridNftDialogOpen(true)
              }}
              useGlitchEffect
              style={{
                // ...style,
                width: '100%',
                height: '100%',
              }}
            />
          </FramesWrapperView>
        )}
        {gridNftDialogOpen && (
          <GridNftPreviewDialog
            dialogTitle={`Grid #${currentSelectedState}`}
            open={gridNftDialogOpen}
            image={currentSelectedStateImage}
            tokenId={currentSelectedState}
            onClose={() => {
              setGridNftDialogOpen(false)
            }}
          />
        )}
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Footer />
        </Box>
      </Box>
    </>
  )
}

export default LivePage
